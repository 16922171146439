import { required } from '@kaliber/forms/validation'
import { useForm, snapshot } from '@kaliber/forms'
import { trackInteraction } from './trackInteraction'


export function useRewardCalculatorForm({ initialData }) {

  const initialValues =  {
    period: 'month',
    hours: initialData.hours,
    scale: initialData.scale,
    salaryMin: initialData.min,
    salaryMax: initialData.max,
    basicPay: true,
    holidayPay: false,
    extraMonth: false,
    ebb: false,
  }

  const { form } = useForm({
    initialValues,
    fields: {
      period: required,
      hours: required,
      salaryMin: required,
      salaryMax: required,
      scale: required,
      basicPay: required,
      holidayPay: required,
      extraMonth: required,
      ebb: required,
    },
  })

  const { scale, hours, period, basicPay, extraMonth, holidayPay, ebb } = initialData

  useOnRewardFieldChange('scale', 'slide', { form, initialData: scale, normalize: x => parseInt(x, 10) })
  useOnRewardFieldChange('hours', 'click', { form, initialData: hours })
  useOnRewardFieldChange('period', 'click', { form, initialData: period })
  useOnRewardFieldChange('basicPay', 'toggle', { form, initialData: basicPay })
  useOnRewardFieldChange('extraMonth', 'toggle', { form, initialData: extraMonth })
  useOnRewardFieldChange('holidayPay', 'toggle', { form, initialData: holidayPay })
  useOnRewardFieldChange('ebb', 'toggle', { form, initialData: ebb })

  return form
}

function useOnRewardFieldChange(fieldName, action, { form, initialData, normalize = x => x }) {
  const currentValueRef = React.useRef(initialData)

  React.useEffect(
    () => {
      const field = form.fields[fieldName]
      const unsubscribe = snapshot.subscribe(field, x => {
        if (normalize(x.value) === normalize(currentValueRef.current)) return
        currentValueRef.current = x.value
        trackInteraction(action, fieldName)
      })

      return () => unsubscribe()
    },
    []
  )
}
