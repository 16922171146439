import { useTranslate } from '/machinery/I18n'
import { useFormField, snapshot } from '@kaliber/forms'
import { Tooltip } from '/features/buildingBlocks/reward-calculator/Tooltip'

import styles from './HoursPerWeek.css'

export function HoursPerWeek({ field, layoutClassName, salaryScaleField }) {
  const hours = [24, 28, 32, 36, 40]
  const { __ } = useTranslate()
  const { name, state, eventHandlers: { onChange } } = useFormField(field)
  const { value } = state
  const [visibleActive, setVisibleActive] = React.useState(false)

  React.useEffect(
    () => {
      const unsubscribe = snapshot.subscribe(salaryScaleField, x => {
        const scale = parseInt(x.value, 10)
        if (scale === 12 || scale === 13) {
          setVisibleActive(true)
        } else {
          setVisibleActive(false)
        }

      })
      return () => unsubscribe()
    },
    []
  )

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <div
        role="radiogroup"
        aria-labelledby="hours-label"
        className={styles.hours}
      >
        <div className={styles.hoursHeader}>
          <span>{__`hours-per-week`}</span>
          <Tooltip layoutClassName={styles.tooltipLayout} title={__`hours-title`} text={__`hours-text`} />
        </div>

        <div className={styles.buttons}>
          {hours.map((x, i) => (
            <label
              key={i}
              className={cx(
                styles.hoursLabel,
                x === value && styles.active,
                x === 40 && !visibleActive && styles.visibleActive)}
            >
              <input
                aria-label={`${x} ${__`hours-per-week`}`}
                aria-checked={Number(x) === Number(value)}
                id={`${x}-hours`}
                type='radio'
                value={Number(x)}
                checked={Number(x) === Number(value)}
                className={styles.hoursInput}
                data-x={`${x}-hours-per-week`}
                onChange={(e) => onChange(Number(e.target.value))}
                {...{ name }}
              />
              <span>{x}</span>
            </label>
          ))}
        </div>
      </div>
    </div>
  )
}
