import { Icon } from '/features/buildingBlocks/Icon'
import { useFloating, autoUpdate, useHover, useInteractions, useFocus, useDismiss, useRole } from '@floating-ui/react'
import { useMediaQuery } from '@kaliber/use-media-query'
import mediaStyles from '/cssGlobal/media.css'

import iconArrow from '/images/icons/arrow-diagonal.raw.svg'
import styles from './Tooltip.css'

export function Tooltip({ text, title, layoutClassName = undefined }) {
  const isMd = useMediaQuery(mediaStyles.viewportMd)
  const {
    getReferenceProps,
    getFloatingProps,
    isOpen,
    refs,
    floatingStyles
  } = useFloatingValues()

  return (
    <div className={cx(styles.component, layoutClassName)}>
      <button
        className={cx(styles.tooltipButton, isOpen && styles.active)}
        {...getReferenceProps()}
        ref={refs.setReference}
      >
        <Icon icon={iconArrow} />
      </button>
      {isOpen && (
        <div
          {...getFloatingProps()}
          style={{
            ...floatingStyles,
            top: isMd && (floatingStyles.top ? `${parseFloat(floatingStyles.top) - 20}px` : undefined),
            left: isMd && (floatingStyles.left ? `${parseFloat(floatingStyles.left) + 8}px` : undefined),
          }}
          className={styles.tooltip}
          aria-hidden={!isOpen}
          ref={refs.setFloating}
        >
          <h4 className={styles.title}>{title}</h4>
          <p>{text}</p>
        </div>)}
    </div>
  )
}

function useFloatingValues() {
  const [isOpen, setIsOpen] = React.useState(false)
  const isMd = useMediaQuery(mediaStyles.viewportMd)
  const { refs, floatingStyles, context } = useFloating({
    open: isOpen,
    onOpenChange: setIsOpen,
    placement: isMd ? 'top-end' : 'top-start',
    whileElementsMounted: autoUpdate,
  })
  const hover = useHover(context, { move: false })
  const focus = useFocus(context)
  const dismiss = useDismiss(context)
  const role = useRole(context, {
    role: 'tooltip',
  })

  const { getReferenceProps, getFloatingProps } = useInteractions([
    hover,
    focus,
    dismiss,
    role,
  ])

  return {
    getReferenceProps,
    getFloatingProps,
    isOpen,
    refs,
    floatingStyles
  }
}
